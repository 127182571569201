
body {
  background: #f1f1f1;
  padding:50px;
}

div#crypto-container {
  background:white;
  width: 70%;
  margin: 0 auto 4px auto;
  padding: 1em;
  box-shadow: 1px 1px 0 lightgrey;
}

span.left {
  font-weight: bold;
}

span.right {
  float:right;
}

h1.crypto {
    text-align: center;
    padding:50px;
    font-weight: bold;
}
